import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import { getData } from "../../helper/helper";
import Layout from "../../components/Layout";

const DocPage = ({ pageContext: { locale }, ...props }) => {
  const { edges: posts } = props.data.docPosts;
  const doc = getData(locale).docPage;

  return (
    <Layout locale={locale} title={doc.title} description={doc.description}>
      <Helmet>
        <title>{doc.title}</title>
        <meta name="description" content={`${doc.description}`} />
      </Helmet>
      <section className="subPage">
        <div
          className="image"
          style={{
            backgroundImage: `url('${doc.image}')`,
          }}
        >
          <div className="titles">
            <h1>{doc.title}</h1>
            <h2>{doc.description}</h2>
          </div>
        </div>
        <div className="container services">
          <div className="columns">
            {posts.map(({ node: post }) => (
              <Link
                className="column"
                key={post.fields.slug}
                to={`${post.fields.slug}`}
                title={post.frontmatter.title}
              >
                <div className="cover">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${
                        !!post.frontmatter.featuredimage.childImageSharp
                          ? post.frontmatter.featuredimage.childImageSharp.fluid
                              .src
                          : post.frontmatter.featuredimage
                      })`,
                    }}
                  />
                  <div className="content">
                    <h4>{post.frontmatter.title}</h4>
                    <p>{post.frontmatter.description}</p>
                  </div>
                  <span>
                    <p>{doc.button}</p>
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DocPage;

DocPage.propTypes = {
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query DocPageQuery($locale: String) {
    docPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          pageKey: { eq: "page_docpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            pageKey
            description
            locale
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 350, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
